import React from 'react'
import styled from 'styled-components'
import { media } from 'ui/responsive'
import { Data } from '../../types'
import { Item } from './Item'

type LegendProps = {
  data: Data
  colorScale: string[]
}

/* 
  implements an algorithm to ensure rounded percentages total 100%
  https://stackoverflow.com/a/13483486
*/
export const roundPercentages = (values: number[]) => {
  const sum = values.reduce((memo, v) => memo + v, 0)
  const percentages = values.reduce(
    (memo, v) => [...memo, (v / sum) * 100],
    [] as number[],
  )

  let cumulative = 0
  let baseline = 0

  return percentages.reduce((memo, p) => {
    cumulative += p
    const rounded = Math.round(cumulative)
    const percentage = rounded - baseline
    baseline = rounded

    return [...memo, percentage]
  }, [] as number[])
}

const Legend: React.FC<React.PropsWithChildren<LegendProps>> = ({ data, colorScale }) => {
  const values = data.reduce((memo, d) => [...memo, d.y], [] as number[])
  const percentages = roundPercentages(values)

  return (
    <Container>
      {data.map((d, i) => (
        <ItemStyled
          key={d.x as number}
          color={colorScale[i % colorScale.length]}
          label={d.label || ''}
          percentage={percentages[i]}
        />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${media.atDesktop`
    display: block;
  `}
`

const ItemStyled = styled(Item)`
  margin-bottom: 1.25rem;
`

export { Legend }
